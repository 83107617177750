.container-row-completeduser {
  max-width: 100%;
}

.container-completeduser {
  padding: 128px;
}

.container-card-completeduser {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  mix-blend-mode: normal;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.foto-completeduser {
  max-width: 100%;
  width: 193px;
  height: 209px;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-completeduser {
    padding: 30px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
