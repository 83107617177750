.container-sidebar {
  display: flex;
  position: relative;
}
.container-sidebar .sidebar-left {
  padding-top: 100px;
  width: 376px;
  height: 100%;
  font-size: 19px;
  background-image: linear-gradient(0deg, #63cfbb, #338274);
}
.grid-sidebar {
  display: grid;
  margin-bottom: 30px;
  margin-top: 30px;
}
.grid-sidebar:hover {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  cursor: pointer;
}
.icon-sidebar-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-active {
  color: #1da1f2;
  font-weight: bold;
}

.foto-sidebar {
  max-width: 100%;
  height: 241px;
  width: 169px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
}
/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
