.img-dropdown {
  max-width: 100%;
  height: 24px;
  width: 34px;
  display: inline-block;
  padding-right: 10px;
}

.kotak-dropdown {
  padding-right: 40px;
}

#basic-nav-dropdown {
  color: aliceblue;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
