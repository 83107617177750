.container-row-profilsayapro {
  max-width: 100%;
}

.container-profilsayapro {
  padding: 128px;
}

.card-profilpro {
  width: 25rem;
  margin: auto;
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  border-radius: 10px;
}

.foto-profilprosaya {
  max-width: 100%;
  height: auto;
  width: 500px;
}

.tombol-profilpro {
  color: white;
  background-color: #78aff4;
  border-radius: 8px;
  margin-left: 10px;
  box-shadow: -3px 3px #a7ceff, -2px 2px #a7ceff, -1px 1px #a7ceff;
  border: 1px solid #a7ceff;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-profilsaya {
    padding: 0px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
