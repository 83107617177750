.container-sectiontwo {
  padding-left: 128px;
  padding-right: 128px;
}

.judul-sectiontwo {
  text-align: left;
  font-weight: 500;
  font-size: 36px;
  color: #338274;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.kalimat-sectiontwo {
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  color: #4d4d4d;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.img-sectiontwo {
  max-width: 100%;
  height: 500px;
  width: 100%;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-sectiontwo {
    padding-left: 30px;
    padding-right: 30px;
  }

  .judul-sectiontwo {
    font-size: 16px;
  }

  .kalimat-sectiontwo {
    font-size: 14px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
