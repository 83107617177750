.container-booking {
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 128px;
  padding-right: 128px;
}

.card-booking {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.card-booking-pembayaran {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 30px;
  padding-top: 50px;
}

.judul-booking {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: white;
}

.img-booking {
  max-width: 100%;
  width: 193px;
  height: 209px;
}

.container-card-booking {
  padding-left: 100px;
  padding-right: 100px;
}

.img-pembayaran-booking {
  max-width: 100%;
  width: auto;
  height: 50px;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-booking {
    padding-left: 40px;
    padding-right: 0px;
  }

  .container-card-booking {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
