.container-navbar {
  padding-left: 128px;
  padding-right: 128px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.button-navbar {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  border-radius: 8px;
  margin-left: 10px;
  border: 1px solid #a7ceff;
}

.nav-link {
  font-weight: 1000;
  font-size: 16px;
  color: #ffffff;
}

/* Dropdown */
.kebawah-tombol {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  color: white;
  font-size: 16px;
  padding: 8px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 10px;
  border: 1px solid #a7ceff;
  

}

.kebawah {
  position: relative;
  display: inline-block;
  background-color: "red" !important;
}

.kebawah-content {
  display: none;
  
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-image: linear-gradient(0deg, #63cfbb, #338274);
}

.kebawah-content .teks-kebawah {
  color: black;
  text-decoration: none;
  display: block;
  padding: 12px 16px;
}

.kebawah-content .teks-kebawah:hover {
  background-color: #f1f1f1;
}

.kebawah:hover .kebawah-content {
  display: block;
}

.kebawah:hover .kebawah-tombol {
  background-color: #0071ff;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-navbar {
    padding-left: 10px;
    padding-right: 10px;
  }

  .button-navbar {
    margin-left: 0px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
