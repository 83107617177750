.container-profesi {
  padding-left: 128px;
  padding-right: 128px;
}

.img-profesi {
  height: 74px;
  width: 74px;
}

.p-profesi {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #4d4d4d;
}

.judul-profesi {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 60px;
  color: #338274;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container-profesi-logo {
  padding: 25px;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-profesi {
    padding-left: 0px;
    padding-right: 0px;
    
  }
  .row-profesi {
    max-width: 100%;
  }
  .judul-profesi {
    font-size: 16px;
    text-align: center;
  }

  .img-profesi {
    height: 44px;
    width: 44px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {

}
