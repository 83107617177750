.container-sliderproduk {
  margin-top: 100px;
  margin-bottom: 100px;
  padding-left: 110px;
  padding-right: 110px;
  max-width: 100%;
}

.judul-sliderproduk {
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  color: #338274;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 100%;
}

.kalimat-sliderproduk {
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  color: #4d4d4d;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 100%;
}

.lihatsemua {
  text-align: right;
  max-width: 100%;
}

.out {
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
}

.cardsliderproduk {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  opacity: 0.8;
  text-align: center;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  width: 200px;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 100%;
}

.img-sliderproduk {
  height: 200px;
  width: 179px;
  max-width: 100%;
}

.card-body {
  padding: 0px;
  max-width: 100%;
}

.card-body p {
  color: aliceblue;
  max-width: 100%;
}

.card-title {
  color: aliceblue;
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 0 !important;
  max-width: 100%;
}

.card-contenta {
  color: aliceblue;
  max-width: 100%;
}

img {
  display: block;
  margin: auto;
  max-width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.tombol-sliderproduk {
  margin-top: 10px;
  background-color: #338274;
  width: 200px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  max-width: 100%;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-sliderproduk {
    padding-left: 30px;
    padding-right: 30px;
  }

  .cardsliderproduk {
    margin-left: 60px;
  }
  .card {
    max-width: 100%;
    width: 100px;
  }

  .judul-sliderproduk {
    font-size: 16px;
  }

  .kalimat-sliderproduk {
    font-size: 14px;
  }

  .lihatsemua {
    font-size: 14px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1400.98px) {
  /* .container-sliderproduk {
    padding-left: 110px;
    padding-right: 80px;
  } */
  .cardsliderproduk {
    width: 150px;
  }

  .img-sliderproduk {
    height: 160px;
    width: 140px;
    max-width: 100%;
  }
}
