.container-about {
  padding-top: 100px;
  padding-left: 128px;
  padding-right: 128px;
  padding-bottom: 100px;
}

.wrapper_team {
  display: block;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  padding: 0rem 0rem 1rem 0rem;
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  height: 100%;
}

.title_team {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  color: #ffffff;
  margin-top: 0 !important;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}

.judul-team-about {
  font-size: 4rem;
  margin-bottom: 0;
  font-weight: 800;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.05em;
}

.deskripsi-team-about {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 800;
  color: white;
}

.teamcontent {
  display: none;
  margin-bottom: 15px;
}

.team_box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
}

.container-left {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  float: left;
  width: 30%;
}

.container-right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  width: 50%;
}

.team {
  display: block;
  max-width: unset;
}

ul li:before {
  display: none;
}

.team li {
  display: block;
  float: left;
  width: 16%;
  margin-right: 0px;
  padding-left: unset;
}
.team li:hover {
  cursor: pointer;
}

.team li:last-child {
  margin-right: 0;
}

.team .hcontent {
  display: none;
}

.teamcontent .bigimg {
  display: block;
  float: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
  width: 220px;
  height: 220px;
  margin: 4rem;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

.bigimg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  background-image: linear-gradient(
    15deg,
    rgba(19, 84, 122, 0.7) 0%,
    rgba(128, 208, 199, 0.7) 100%
  );
}

.profile_container::after {
  content: "";
  display: block;
  width: 70%;
  background: rgba(255, 255, 255, 0.2);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background: -o-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.7) 100%
  );
  left: -100%;
  top: 0;
  height: 100%;
  position: absolute;
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  -ms-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

.profile_container:hover::after {
  left: 150%;
  transition: all 1s;
  -webkit-transition: all 0.75s;
  -moz-transition: all 0.75s;
  -o-transition: all 0.75s;
  -ms-transition: all 0.75s;
}

.profile_container:hover .bigimg-overlay {
  opacity: 1;
}

.bigimg-text {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.profile_container {
  position: relative;
  overflow: hidden;
}

.profilepic {
  width: 200px;
  height: 200px;
  display: block;
}

.teamdetails {
  display: block;
  float: left;
  width: 600px;
  padding-left: 15px;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: flex;
  flex-flow: row nowrap;
}

.flipimg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* untuk responsive */

@media only screen and (max-width: 900px) {
  .container-about {
    padding-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 100px;
  }

  .teamdetails {
    width: unset;
    padding-left: unset;
  }
  li {
    padding-left: unset;
  }
  .profile_container {
    width: 100%;
  }
  .teamcontent .bigimg {
    margin: 2rem 0;
  }
  .bigimg-overlay {
    opacity: 1;
  }
  .team li {
    margin-right: unset;
    width: 100%;
  }
  #wrapper_team {
    padding: 0;
  }
  .clearfix {
    flex-flow: column nowrap;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
