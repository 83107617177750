.containerprofessional {
  padding-top: 100px;
  padding-left: 128px;
  padding-right: 128px;
}

/* search bar Styling */
.search {
  width: 100%;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}

.icon {
  padding: 10px;
  min-width: 50px;
  text-align: center;
  
}

.searchTerm {
  width: 100%;
  padding: 20px;
  height: 20px;
  outline: none;
  color: #9dbfaf;
  background: #ebfeff;
  border: 0.8px solid #000000;
  box-sizing: border-box;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.searchTerm:focus {
  color: #00b4cc;
}

/* Filter Styling */
.containerfilter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.box {
  padding: 20px;
  
}

.box select {
  background-color: #63cfbb;
  color: white;
  padding: 12px;
  width: 350px;
  border: none;
  font-size: 16px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  border-radius: 4px;
}

.box::before {
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}

/* Styling card professional */

.tulisanpenandaprofessional {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.judulprofessional {
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  color: #4d4d4d;
}

.containerprofessionallist {
  padding-left: 25px;
  padding-bottom: 50px;
}

.cardprofessional {
  background-image: linear-gradient(0deg, #63cfbb, #338274);
  text-align: center;
  padding-top: 10px;
  width: 200px;
  mix-blend-mode: normal;
  opacity: 0.8;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.cardtitle {
  color: aliceblue;
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 10px;
  margin-bottom: 0 !important;
}
.card-body p {
  margin-bottom: 0px;
  color: aliceblue;
}

.tombol-professional {
  background-color: #338274;
  border-radius: 8px;
  margin-left: 0px;
  margin-top: 20px;
  width: 200px;
  border: 1px solid #a7ceff;
}

.moveprofessional {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .containerfilter {
    flex-direction: column;
  }

  .search {
    padding-top: 100px;
  }

  .box select {
    width: 200px;
  }

  .containerprofessional {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .containerprofessionallist {
    padding-left: 100px;
  }

  .rowresponsive {
    max-width: 100%;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
