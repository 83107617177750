.container-sectionfour {
  padding-left: 128px;
  padding-right: 128px;
}

.garispemisah-sectionfour {
  border-top: 3px solid #338274;
}

.judul-sectionfour {
  text-align: center;
  font-weight: 500;
  font-size: 36px;
  color: #338274;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.kalimat-sectionfour {
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  color: #4d4d4d;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.img-circle-sectionfour {
  max-width: 100%;
  height: 161px;
  width: 161px;
  position: relative;
  top: 0;
  left: 0;
}

.img-vector-sectionfour {
  max-width: 100%;
  height: 143px;
  width: 143px;
  position: absolute;
  top: 20px;
  left: 150px;
}

.judul-carapesan-sectionfour {
  font-weight: normal;
  font-size: 16px;
  color: #4d4d4d;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container-carapesan-sectionfour {
  padding-top: 20px;
}

.container-kolom-sectionfour {
  position: relative;
  top: 0;
  left: 0;
}

.garis-carapesan-sectionfour {
  max-width: 100%;
  padding-bottom: 50px;
}
/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-sectionfour {
    padding-left: 0px;
    padding-right: 0px;
  }

  .judul-sectionfour {
    font-size: 16px;
  }

  .img-circle-sectionfour {
    height: 91px;
    width: 91px;
  }

  .garis-carapesan-sectionfour {
    padding-bottom: 10px;
  }

  .img-vector-sectionfour {
    height: 73px;
    width: 73px;
    top: 20px;
    left: 20px;
  }

  .judul-carapesan-sectionfour {
    font-size: 14px;
  }

  .container-carapesan-sectionfour {
    max-width: 100%;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
