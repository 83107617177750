.container-sectionthree {
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 128px;
  padding-right: 128px;
  background: #63cfbb;
  mix-blend-mode: normal;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
  
}

.img-sectionthree {
  height: 100px;
  width: 100px;
}

.judul-sectionthree {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #338274;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.kalimat-sectionthree {
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .container-sectionthree {
    padding-left: 30px;
    padding-right: 30px;
  }

  .img-sectionthree {
    height: 50px;
    width: 50px;
  }

  .judul-sectionthree {
    font-size: 16px;
  }

  .kalimat-sectionthree {
    font-size: 10px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
