/* untuk responsive */
.success {
  font-family: "Roboto";
  background-color: #ebfeff;
  color: #555555;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

/* success */
.successbtn {
  margin-top: 5vh;
}

.totaltagihan {
  margin-top: 10vh;
  color: #555555;
}

.totaltagihan p {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
}

.metodepembayaran {
  margin-top: 5vh;
}

.marbot {
  margin-bottom: 5vh;
}

.totag {
  margin-top: 5vh;
}

/* button */
.btnconfirmation {
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 2vh;
  padding-left: 10vh;
  padding-right: 10vh;
  background-color: #78aff4;
}

.img-success {
  max-width: 100%;
  height: 200px;
  width: auto;
}
/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
