.logosignlog {
  padding-top: 0px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  height: 100px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 200px;
  height: auto;
}

.section-signupb {
  padding-top: 50px;
  padding-bottom: 100px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(https://res.cloudinary.com/def4tydoe/image/upload/v1603897150/christiann-koepke-WiE01mC9AtY-unsplash_gekbku.jpg);
  /* margin-top: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 5%; */
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
}

.gambar-sectionb {
  width: 400px;
  height: 650px;
  display: flex;
}

.form-sectionb {
  width: 400px;
  height: 650px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(90deg, #63cfbb, #338274);
  opacity: 0.8;
  border-radius: 5%;
}

.form-sectionb h4 {
  color: aliceblue;
}

.formb {
  font-family: "Roboto", sans-serif;
}

.buttonb {
  width: 250px;
  background-image: linear-gradient(0deg, #63cfbb, #338274);
}

.option-signupb {
  display: flex;
  flex-direction: row;
  font-size: smaller;
}

.option-signupb h6 {
  color: aliceblue;
}

.label-formb {
  color: aliceblue;
  margin-bottom: 0px;
}

.kolom-formb {
  width: 250px;
}

.option-textb {
  font-size: small;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
