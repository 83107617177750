.jumbotron {
  background: linear-gradient(180deg, #ededff 0%, rgba(255, 255, 255, 0) 100%),
    url(https://res.cloudinary.com/def4tydoe/image/upload/v1604378233/jumbotron_jiqcl4.jpg);
  color: white !important;
  /* background-attachment: fixed; */
  padding-top: 10rem;
  padding-bottom: 15rem;
  max-width: 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}

.container-sectionone {
  padding-bottom: 50px;
}

.judul-sectionone {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 60px;
  color: #338274;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.img-sectionone {
  max-width: 100%;
  height: 88px;
  width: 88px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.button-sectionone {
  background: #63cfbb;
  border-radius: 8px;
  box-shadow: -3px 3px #a7ceff, -2px 2px #a7ceff, -1px 1px #a7ceff;
  border: 1px solid #63cfbb;
  opacity: 0.6;
}

/* untuk responsive */

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .jumbotron {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-size: cover;
  }

  .judul-sectionone {
    font-size: 20px;
  }

  h3 {
    font-size: 15px;
  }

  .img-sectionone {
    height: 70px;
    width: 70px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}
